import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ReactiveFormsModule } from '@angular/forms';
import { HeaderComponent } from './components/header/header.component';

export const Components = [
  HeaderComponent
];

export const Modules = [
    FontAwesomeModule,
    ReactiveFormsModule
];
