<!-- <div [ngClass]="{'container-fluid': basic === true}"> -->
<div class="container-fluid">
  <div class="row">
    <div class="col-12 d-flex justify-content-between my-2">
      <div class="d-flex align-content-center">
        <svg xmlns="http://www.w3.org/2000/svg" width="162.982" height="162.963" viewBox="0 0 162.982 162.963" style="cursor: default;">
          <defs>
            <style>
              .a {
                fill: #fff;
              }
              .b {
                fill: #153c91;
              }
            </style>
          </defs>
          <g transform="translate(81.491 81.481)">
            <g transform="translate(-81.491 -81.481)">
              <path class="a"
                d="M155.357,63.046,99.967,7.656a26.22,26.22,0,0,0-36.942,0L7.634,63.046a26.173,26.173,0,0,0,0,36.942l55.39,55.39a26.173,26.173,0,0,0,36.942,0l55.39-55.39A26.173,26.173,0,0,0,155.357,63.046ZM42.985,89.219a10.876,10.876,0,0,1-15.358-15.4L66.115,35.328l7.679-7.679a10.909,10.909,0,0,1,15.4,0l7.679,7.679,7.679,7.679a10.893,10.893,0,1,1-15.4,15.4l-7.679-7.679-15.4,15.4L50.71,81.494Zm92.332,0L89.151,135.386a10.863,10.863,0,0,1-15.4,0l-15.4-15.4a10.892,10.892,0,0,1,15.4-15.4l7.679,7.679,30.855-30.762,7.679-7.679a10.893,10.893,0,0,1,15.4,15.4Z"
                transform="translate(-0.004 -0.045)" />
              <path class="b"
                d="M201.235,161.614a10.816,10.816,0,0,1-3.184,7.679l-46.166,46.166a10.863,10.863,0,0,1-15.4,0l-15.4-15.4a10.892,10.892,0,0,1,15.4-15.4l7.679,7.679,30.809-30.715,7.679-7.679a10.863,10.863,0,0,1,18.588,7.679Z"
                transform="translate(-62.692 -80.165)" />
              <path class="b"
                d="M132.351,86.146a10.863,10.863,0,0,1-15.4,0l-7.679-7.679-15.4,15.4-15.4,15.357-7.679,7.726a10.892,10.892,0,1,1-15.4-15.4L93.864,63.063l7.679-7.679a10.909,10.909,0,0,1,15.4,0l7.679,7.679,7.679,7.679a10.863,10.863,0,0,1,.047,15.4Z"
                transform="translate(-27.754 -27.781)" />
            </g>
          </g>
        </svg>
        <div class="d-flex align-items-center">
          <span class="ml-4 text-white bold d-none d-md-block title-wrap-small" style="cursor: default;">SCHOOLS SELF SCREENING</span>
        </div>
      </div>
    </div>
  </div>
</div>
